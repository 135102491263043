/* eslint-disable no-process-env */
import {isoWindow} from '~/utils/window';

// We need to detect if the code is running on a Spin instance during run time,
// for checkout-web. As a result, we can't use the NODE_ENV env variable.
const spinFqdnMatch = isoWindow?.location?.hostname?.match(
  /([^.]*[.]){2}(eu|us|asia).spin.dev/,
);
const spinFqdn =
  spinFqdnMatch && spinFqdnMatch.length > 0 ? spinFqdnMatch[0] : null;

export const CORE_AUTH_DOMAIN = spinFqdn
  ? isoWindow.location.origin
  : process.env.CORE_AUTH_DOMAIN || isoWindow.location.origin;

export const PAY_AUTH_DOMAIN = spinFqdn
  ? `https://shop-server.${spinFqdn}`
  : process.env.PAY_AUTH_DOMAIN;

export const PAY_AUTH_DOMAIN_ALT = spinFqdn
  ? `https://pay-shopify-com.${spinFqdn}`
  : process.env.PAY_AUTH_ALT_DOMAIN;

import type {AuthorizeState, AuthorizeStateAction} from './types';

export function reducer(state: AuthorizeState, action: AuthorizeStateAction) {
  switch (action.type) {
    case 'setAutoOpened':
      return {...state, autoOpened: action.payload};
    case 'setLoaded':
      return {...state, loaded: action.payload};
    case 'setModalDismissable':
      return {...state, modalDismissable: action.payload};
    case 'setModalVisible':
      return {
        ...state,
        modalVisible: action.payload,
        // Not dismissable until the transition is complete
        ...(action.payload && {modalDismissable: false}),
      };
    case 'setSessionDetected':
      return {...state, sessionDetected: action.payload};
    case 'updateState':
      return {...state, ...action.payload};
    default:
      return state;
  }
}
